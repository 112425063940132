import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import "react-multi-carousel/lib/styles.css";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GridUn from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import * as queries from "../../../../operations/queries";
import Popover from "@mui/material/Popover";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import "./SearchResult.scss";
import { CountryContext } from "../../MainComponent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";
import ProductMainCard from "../../../../components/productMainCard/ProductMainCard";
import CartModal from "../../../../components/CarouselSlider/cartmodal/CartModal";
import MainCardSkelton from "../../../../components/skelton/MainCardSkelton";

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 3H5C3.586 3 2.879 3 2.44 3.412C2 3.824 2 4.488 2 5.815V6.505C2 7.542 2 8.061 2.26 8.491C2.52 8.921 2.993 9.189 3.942 9.723L6.855 11.363C7.491 11.721 7.81 11.9 8.038 12.098C8.512 12.509 8.804 12.993 8.936 13.588C9 13.872 9 14.206 9 14.873V17.543C9 18.452 9 18.907 9.252 19.261C9.504 19.616 9.952 19.791 10.846 20.141C12.725 20.875 13.664 21.242 14.332 20.824C15 20.407 15 19.452 15 17.542V14.872C15 14.206 15 13.872 15.064 13.587C15.1896 13.0042 15.5059 12.4798 15.963 12.097C16.19 11.9 16.509 11.721 17.145 11.362L20.058 9.722C21.006 9.189 21.481 8.922 21.74 8.492C22 8.062 22 7.542 22 6.504V5.814C22 4.488 22 3.824 21.56 3.412C21.122 3 20.415 3 19 3Z"
      stroke="#118FA7"
      stroke-width="1.5"
    />
  </svg>
);

function SearchResult({ onLoad, isCountry }: any) {
  let minFilterPrice: number = 0;
  let maxFilterPrice: number = 0;
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedMenufacturer, setSelectedMenufacturer] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { store } = useContext(CountryContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [priceValue, setPriceValue] = useState<number[]>([
    minFilterPrice,
    maxFilterPrice,
  ]);
  const [fetchProd, setFetchPro] = useState(false);
  const [filteredPro, setFilteredPro] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [totalPage, setTotalPage] = useState<any>();
  const [decodedString, setDecodedString] = useState("");
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [modalUrlKey, setModalUrlKey] = useState("");
  const [productData,setProductData] = useState("");
  const open = Boolean(anchorEl);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setFetchPro(true);
    setPriceValue(newValue as number[]);
  };
  function valuetext(value: number) {
    return `${value}AED`;
  }
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [filteredPro]);

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };

  const url = location?.pathname?.split("search/");
  const urlKey = url[url.length - 1];
  const searchItem = urlKey.split(".html");
  const searchItemHeader = urlKey.split(".html")[0].replace(/\+/g, " ");

  const { data: categoryid, refetch: refatchId } = useQuery(
    queries.cartQueries.GET_CATEGORY_URL,
    {
      variables: { url: urlKey },
      skip: !urlKey,
    }
  );

  useEffect(() => {
    refatchId();
  }, [urlKey, refatchId , store]);

  const {
    loading: decodeLoading,
    error: decodeError,
    data: decodeData,
  } = useQuery(queries.configurationQueries.DECODE_STRING_QUERY, {
    variables: { encryptedStr: searchItem[0] },
  });

  // Handling decode loading and error
  useEffect(() => {
    if (decodeLoading) {
    }
    if (decodeError) {
    }
  }, [decodeLoading, decodeError]);

  // Handling decoded data
  useEffect(() => {
    if (
      decodeData &&
      decodeData.decodeString &&
      decodeData.decodeString.length > 0
    ) {
      const decodedString = decodeData.decodeString[0].decStr;
      setDecodedString(decodedString);
    }
  }, [decodeData]);
  // Conditional Search Query
  const { data: searchRes, refetch: refetchSearch , loading : searchResLoading } = useQuery(
    queries.headerQueries.SEARCH_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        searchText:
          store === "ae_ar" || store === "sa_ar" || store === "ar"
            ? decodedString
            : searchItem[0],
        currentPage: currentPage,
      },
      context: {
        headers: customHeader,
      },
    }
  );
  const searchData = searchRes?.products?.items;

  useEffect(() => {
    refatchId();
  }, [urlKey, refatchId]);

  useEffect(() => {
    refetchSearch();
  }, [currentPage, refetchSearch , store]);

  var category_id = categoryid?.urlResolver?.id?.toString();

  const { data: catDetails /* , refetch: refetchCatDetails */ } = useQuery(
    queries.productQueries.GET_CATEGORY_DETAILS,
    {
      variables: { id: category_id },
      context: {
        headers: customHeader,
      },
    }
  );
  const catMetaData = catDetails?.category;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;
  // const customHeader = {
  //     store: country,
  //   };
  const { data: filterDataRes, refetch: refetchProd } = useQuery(
    queries.productQueries.GET_PRODUCTS_FILTER,
    {
      variables: {
        categoryId: category_id,
        from: priceValue[0],
        ...(priceValue[1] > 0 && { to: priceValue[1] }),
        // categoryId : '1780',
        manufacturerName: selectedMenufacturer,
        pageSize: 25,
      },
    }
  );
  const filterProductItems = filterDataRes && filterDataRes?.products?.items;

  useEffect(() => {
    if (!fetchProd) {
      const currnetPages = searchRes?.products?.page_info?.current_page;
      const totalPages = searchRes?.products?.page_info?.total_pages;
      setFilteredPro(searchData);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages);
      }
    } else {
      const currnetPages = filterDataRes?.products?.page_info?.current_page;
      const totalPages = filterDataRes?.products?.page_info?.total_pages;
      setFilteredPro(searchData);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages);
      }
      setFilteredPro(filterProductItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProductItems, searchData]);

  useEffect(() => {
    refetchProd();
  }, [fetchProd, category_id, refetchProd]);

  const filterAggregationProducts =
    searchRes && searchRes?.products?.aggregations;

  filterAggregationProducts &&
    // eslint-disable-next-line array-callback-return
    filterAggregationProducts.map((data: any) => {
      if (data?.attribute_code === "price") {
        const minPriceProduct = data?.options.reduce(
          (min: any, currentProduct: any) => {
            return parseInt(currentProduct.value) < parseInt(min.value)
              ? currentProduct
              : min;
          },
          data?.options[0]
        );
        const maxPriceProduct = data?.options.reduce(
          (max: any, currentProduct: any) => {
            return parseInt(currentProduct.value) > parseInt(max.value)
              ? currentProduct
              : max;
          },
          data?.options[0]
        );
        maxFilterPrice = parseInt(maxPriceProduct?.value);
        minFilterPrice = parseInt(minPriceProduct?.value);
      }
    });
  useEffect(() => {
    setPriceValue([0, maxFilterPrice]);
  }, [minFilterPrice, maxFilterPrice]);

  function handleChipClick(item: any) {
    // setSelectedMenufacturer(item?.value);
    setFetchPro(true);
    const index = selectedMenufacturer.indexOf(item?.value);
    if (index === -1) {
      setSelectedMenufacturer([...selectedMenufacturer, item?.value]);
    } else {
      const a = selectedMenufacturer.filter(
        (data: any) => data !== item?.value
      );
      setSelectedMenufacturer(a);
    }
  }
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Helmet>
        <title>{catMetaData?.meta_title}</title>
        <meta name="description" content={catMetaData?.meta_description} />
        <meta name="keywords" content={catMetaData?.meta_keywords} />
        <meta name="DC.title" content="Jebelz Marketplace" />
        <meta name="geo.region" content={store === "in" ? "IN-UP" : "AE-DU"} />
        <meta
          name="geo.placename"
          content={store === "in" ? "Noida" : "Dubai"}
        />
        <meta
          name="geo.position"
          content={
            store === "in" ? "28.58508;77.315262" : "25.121614;55.377031"
          }
        />
        <meta
          name="ICBM"
          content={
            store === "in" ? "28.58508, 77.315262" : "25.121614, 55.377031"
          }
        />
      </Helmet>
      <div className="main-content search-main-content">
        <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="category-home-grid"
              >
                <CardHeader
                  className="section-title searchresult-section"
                  style={{ paddingTop: 0 }}
                  titleTypographyProps={{ variant: "h5" }}
                  title={`${t("main.search-result-title")}: ${
                    store === "ae_ar" || store === "sa_ar" || store === "ar"
                      ? decodedString
                      : searchItemHeader
                  }`}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
        <div>
          <Box sx={{ pt: 5 }}>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0 }}
              className="topsell-machine-grid"
              style={{
                direction:
                  store === "ae_ar" || store === "sa_ar" || store === "ar"
                    ? "rtl"
                    : "ltr",
                textAlign:
                  store === "ae_ar" || store === "sa_ar" || store === "ar"
                    ? "right"
                    : "left",
              }}
            >
              <Box
                sx={{
                  minWidth: 120,
                  px: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "15px",
                  marginTop: "13px",
                }}
              >
                <Box
                  className="sortby-btn filter-box filter-input-box"
                  sx={{ width: { md: "297px", sm: "297px", xs: "297px" } }}
                >
                  <Box>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        bgcolor: "#fff",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                      className="filter-btn"
                      endIcon={
                        open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    >
                      <span
                        style={{
                          marginRight: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {filterIcon}
                      </span>
                      <span className="filter-text">
                        {t("inner_pages.domestic_machine.filter")}
                      </span>
                    </Button>
                    <Popover
                      className="filter-popver"
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} md={12}>
                              {filterAggregationProducts &&
                                filterAggregationProducts.map((data: any) => {
                                  return data?.attribute_code !== "price" ? (
                                    <Accordion
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                          // border: "1px solid #66E5FF",
                                          // background: "#10CDF2",
                                          border: "1px solid #ebeaea",
                                          background: "#f8f6f6",
                                          mb: 1,
                                          "&:last-of-type": {
                                            mb: 0,
                                          },
                                        }}
                                      >
                                        <Typography>
                                          {/* {t(
                                                  "inner_pages.domestic_machine.menufacturer"
                                                )} */}
                                          {data?.label}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          border: "1px solid #E5E3E3",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          sx={{
                                            flexWrap: "wrap",
                                            gap: "10px",
                                          }}
                                        >
                                          {data?.options.map((item: any) => {
                                            return (
                                              <Chip
                                                key={item?.value}
                                                onClick={() =>
                                                  handleChipClick(item)
                                                }
                                                label={`${item?.label} (${item?.count})`}
                                                className={`filter-chip ${
                                                  selectedMenufacturer.includes(
                                                    item?.value
                                                  )
                                                    ? "active"
                                                    : ""
                                                }`}
                                                component="a"
                                                href="#"
                                                clickable
                                                variant="outlined"
                                                sx={{
                                                  border: "1px solid #EFEFEF",
                                                  background: "#FAFAFA",
                                                  px: 4,
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            );
                                          })}
                                        </Stack>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  );
                                })}
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                              {filterAggregationProducts &&
                                filterAggregationProducts.map((data: any) => {
                                  return data?.attribute_code === "price" ? (
                                    <Accordion
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "20px",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                          border: "1px solid #66E5FF",
                                          background: "#10CDF2",
                                        }}
                                      >
                                        <Typography>
                                          {t(
                                            "inner_pages.domestic_machine.price"
                                          )}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          border: "1px solid #E5E3E3",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          sx={{ mb: 3 }}
                                        >
                                          <>
                                            <Chip
                                              sx={{
                                                border: "1px solid #EFEFEF",
                                                background: "#FAFAFA",
                                                px: 2,
                                                borderRadius: "5px",
                                              }}
                                              label={`${
                                                filterDataRes?.products
                                                  ?.aggregations &&
                                                filterDataRes?.products
                                                  ?.aggregations[0]?.currency
                                              } ${minFilterPrice}`}
                                              variant="outlined"
                                            />
                                            <Chip
                                              sx={{
                                                border: "1px solid #EFEFEF",
                                                background: "#FAFAFA",
                                                px: 2,
                                                borderRadius: "5px",
                                              }}
                                              label={`${
                                                filterDataRes?.products
                                                  ?.aggregations &&
                                                filterDataRes?.products
                                                  ?.aggregations[0]?.currency
                                              } ${maxFilterPrice}`}
                                              variant="outlined"
                                            />
                                          </>
                                          {/* ); */}
                                          {/* })} */}
                                        </Stack>
                                        <Box sx={{ width: 230 }}>
                                          <Slider
                                            getAriaLabel={() => "Price range"}
                                            value={priceValue}
                                            onChange={handleSliderChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={minFilterPrice}
                                            max={maxFilterPrice}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span>
                                            {t(
                                              "inner_pages.domestic_machine.2365-products"
                                            )}
                                          </span>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  );
                                })}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </Box>
                </Box>
              </Box>
              <CardContent className="category_list">
                {(filteredPro === undefined || searchResLoading) ? (
                  <MainCardSkelton />
                ) : filteredPro?.length > 0 ? (
                  <GridUn
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2, md: 1.5 }}
                    columns={{ xs: 12 }}
                  >
                    {Array.from(Array(filteredPro?.length)).map((_, index) => (
                      <ProductMainCard filteredPro={filteredPro} setCartModalOpen={setCartModalOpen} setModalUrlKey={setModalUrlKey} index={index} setProductData={setProductData}/>
                    ))}
                  </GridUn>
                ) : (
                  <div>
                    {t("inner_pages.domestic_machine.no-product-found")}
                  </div>
                )}
              </CardContent>
            </Card>
          </Box>
          {totalPage && (
            <Box sx={{ pt: 5 }}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPage ? totalPage : 10}
                  shape="rounded"
                  onChange={handlePageChange}
                  defaultPage={selectedPage}
                  className="search-pagination"
                  style={{
                    direction:
                      store === "ae_ar" || store === "sa_ar" || store === "ar"
                        ? "rtl"
                        : "ltr",
                    textAlign:
                      store === "ae_ar" || store === "sa_ar" || store === "ar"
                        ? "right"
                        : "left",
                  }}
                />
              </Stack>
            </Box>
          )}
        </div>
        { isCartModalOpen &&  <CartModal
            open={isCartModalOpen}
            setCartModalOpen={setCartModalOpen}
            urlkey={modalUrlKey} 
            productdata={productData}
          />}
      </div>
    </>
  );
}
export default SearchResult;
