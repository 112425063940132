import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GridUn from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
// import FormControl from "@mui/material/FormControl";
// import NativeSelect from "@mui/material/NativeSelect";
// import InputLabel from "@mui/material/InputLabel";
import { Link, useLocation } from "react-router-dom";
// import { useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
// import theme from "../../../theme";
import Popover from "@mui/material/Popover";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import "./Domestic-machine.scss";
import { CountryContext } from "../MainComponent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProductMainCard from "../../../components/productMainCard/ProductMainCard";
import CartModal from "../../../components/CarouselSlider/cartmodal/CartModal";
import MainCardSkelton from "../../../components/skelton/MainCardSkelton";
// import { log } from "console";
// import { env } from "process";
// import dotenv1 from 'dotenv';

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 3H5C3.586 3 2.879 3 2.44 3.412C2 3.824 2 4.488 2 5.815V6.505C2 7.542 2 8.061 2.26 8.491C2.52 8.921 2.993 9.189 3.942 9.723L6.855 11.363C7.491 11.721 7.81 11.9 8.038 12.098C8.512 12.509 8.804 12.993 8.936 13.588C9 13.872 9 14.206 9 14.873V17.543C9 18.452 9 18.907 9.252 19.261C9.504 19.616 9.952 19.791 10.846 20.141C12.725 20.875 13.664 21.242 14.332 20.824C15 20.407 15 19.452 15 17.542V14.872C15 14.206 15 13.872 15.064 13.587C15.1896 13.0042 15.5059 12.4798 15.963 12.097C16.19 11.9 16.509 11.721 17.145 11.362L20.058 9.722C21.006 9.189 21.481 8.922 21.74 8.492C22 8.062 22 7.542 22 6.504V5.814C22 4.488 22 3.824 21.56 3.412C21.122 3 20.415 3 19 3Z"
      stroke="#118FA7"
      stroke-width="1.5"
    />
  </svg>
);

function BrandPage({ onLoad, isCountry }: any) {
  let minFilterPrice: number = 0;
  let maxFilterPrice: number = 0;
  const location = useLocation();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [selectedMenufacturer, setSelectedMenufacturer] = useState<any>([]);
  // eslint-disable-next-line
  const [selectedSorting, setSelectedSorting] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { store } = useContext(CountryContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [modalUrlKey, setModalUrlKey] = useState("");
  const [productData,setProductData] = useState("");
  const [priceValue, setPriceValue] = useState<number[]>([
    minFilterPrice,
    maxFilterPrice,
  ]);
  const [fetchProd, setFetchPro] = useState(false);
  const [filteredPro, setFilteredPro] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [totalPage, setTotalPage] = useState<any>();
  const open = Boolean(anchorEl);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setFetchPro(true);
    setPriceValue(newValue as number[]);
  };
  function valuetext(value: number) {
    return `${value}AED`;
  }
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [filteredPro]);

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };

  const { data: brandRes,refetch : brandResfetch } = useQuery(
    queries.productQueries.GET_BRANDS_LIST_DETAIL,
    {
      context: {
        headers: customHeader,
      },
    }
  );

  useEffect(()=>{
    brandResfetch()
  },[store,brandResfetch])

  const brandData =
    brandRes?.customAttributeMetadata?.items[0]?.attribute_options ?? [];

  const url = location?.pathname?.split("/brand/");
  const brandname = url[url.length - 1]
    .replaceAll("%20", " ")
    .replaceAll("-", " ")
    .replaceAll(".html", "");

  const brandnames = brandname.split(" ");
  let val = "";
  brandnames.forEach((v) => {
    val += v.charAt(0).toUpperCase() + v.substring(1) + " ";
  });

  const manufacturerFormatted = val.trim();

  const matchedBrand = brandData.find(
    (brand: any) =>
      brand.option_label_en.toLowerCase() ===
      manufacturerFormatted.toLowerCase()
  );

  const manufacturerToUse = matchedBrand
    ? matchedBrand.option_label_en
    : manufacturerFormatted;

  const showLableForContainer = matchedBrand
  ? store.match("ar") ? matchedBrand.option_label_ar : matchedBrand.option_label_en
  : manufacturerFormatted;

  const category_id = 2;

  const { data: categoryProduct, refetch: refetchProductList , loading : categoryProdLoading} = useQuery(
    queries.productQueries.SIMPLE_BRANDS_PRODUCTLIST,
    {
      fetchPolicy: "network-only",
      variables: {
        categoryId: category_id,
        currentPage: currentPage,
        manufacturer: manufacturerToUse,
      },
      context: {
        headers: customHeader,
      },
    }
  );

  const { data: catDetails } = useQuery(
    queries.productQueries.GET_CATEGORY_DETAILS,
    {
      variables: { id: category_id },
      context: {
        headers: customHeader,
      },
    }
  );
  const catMetaData = catDetails?.category;
  const htmlContentEnglish = matchedBrand?.brand_description;
  const htmlContentArabic = matchedBrand?.brand_description_ar;
  let breadcrumbs: any = [];

  if (catMetaData) {
    if (catMetaData.breadcrumbs && catMetaData.breadcrumbs.length > 0) {
      breadcrumbs = [
        <Link key="1" color="inherit" to="/">
          {t("inner_pages.detail_page.home")}
        </Link>,
        <Link
          key="2"
          color="inherit"
          to={`/${store}/category/${catMetaData.breadcrumbs[0].category_url_path}.html`}
        >
          {catMetaData.breadcrumbs[0].category_name}
        </Link>,
        <Link
          key="3"
          color="inherit"
          to={`/${store}/category/${catMetaData.breadcrumbs[0].category_url_path}/${catMetaData.url_key}.html`}
        >
          {catMetaData.name}
        </Link>,
      ];
    } else {
      breadcrumbs = [
        <Link key="1" color="inherit" to="/">
          {t("inner_pages.detail_page.home")}
        </Link>,
        <Link
          key="2"
          color="inherit"
          to={`/${store}/category/${catMetaData.url_key}.html`}
        >
          {catMetaData.name}
        </Link>,
      ];
    }
  }

  // eslint-disable-next-line
  const { data: catRes } = useQuery(queries.homeQueries.GET_CATEGORY_SLIDER, {
    variables: { categoryId: category_id },
    context: {
      headers: customHeader,
    },
  });
  // const catData = catRes?.getSubCategoriesData;
  useEffect(() => {
    //console.log("inside effect............1 3333");
    setCurrentPage(1);
  }, [category_id]);
  useEffect(() => {
    //console.log("inside effect............2");
    refetchProductList();
  }, [category_id, refetchProductList, currentPage , store]);
  let categoryProductList = categoryProduct?.products?.items;
  const filterAggregationProducts =
    categoryProduct && categoryProduct?.products?.aggregations;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  const { data: filterDataRes, refetch: refetchProd } = useQuery(
    queries.productQueries.GET_PRODUCTS_FILTER,
    {
      variables: {
        categoryId: category_id,
        from: priceValue[0],
        to: priceValue[1],
        // categoryId : '1780',
        manufacturerName: selectedMenufacturer,
        sorting: selectedSorting,
        pageSize: 25,
        sortby: {
          price: "ASC",
        },
      },
    }
  );
  const filterProductItems = filterDataRes && filterDataRes?.products?.items;

  useEffect(() => {
    if (!fetchProd) {
      const currnetPages = categoryProduct?.products?.page_info?.current_page;
      const totalPages = categoryProduct?.products?.page_info?.total_pages;
      setFilteredPro(categoryProductList);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages);
      }
    } else {
      const currnetPages = filterDataRes?.products?.page_info?.current_page;
      const totalPages = filterDataRes?.products?.page_info?.total_pages;
      setFilteredPro(categoryProductList);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages);
      }

      setFilteredPro(filterProductItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProductItems, categoryProductList]);

  useEffect(() => {
    if (fetchProd) {
      refetchProd();
    }
    // eslint-disable-next-line
  }, [fetchProd , store]);

  console.log("store",store)

  filterAggregationProducts &&
    // eslint-disable-next-line array-callback-return
    filterAggregationProducts.map((data: any) => {
      if (data?.attribute_code === "price") {
        const minPriceProduct = data?.options.reduce(
          (min: any, currentProduct: any) => {
            return parseInt(currentProduct.value) < parseInt(min.value)
              ? currentProduct
              : min;
          },
          data?.options[0]
        );
        const maxPriceProduct = data?.options.reduce(
          (max: any, currentProduct: any) => {
            return parseInt(currentProduct.value) > parseInt(max.value)
              ? currentProduct
              : max;
          },
          data?.options[0]
        );
        maxFilterPrice = parseInt(maxPriceProduct?.value);
        minFilterPrice = parseInt(minPriceProduct?.value);
      }
    });
  useEffect(() => {
    setPriceValue([0, maxFilterPrice]);
  }, [minFilterPrice, maxFilterPrice]);

  function handleChipClick(item: any) {
    setFetchPro(true);
    const index = selectedMenufacturer.indexOf(item?.value);
    if (index === -1) {
      setSelectedMenufacturer([...selectedMenufacturer, item?.value]);
    } else {
      const a = selectedMenufacturer.filter(
        (data: any) => data !== item?.value
      );
      setSelectedMenufacturer(a);
    }
  }

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Helmet>
        <title>{matchedBrand?.meta_title}</title>
        <meta name="description" content={matchedBrand?.meta_description} />
        <meta name="keywords" content={matchedBrand?.meta_keyword} />
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="breadcrumb-data"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <div className="main-content">
        <div
          style={{
            direction:
              store === "ae_ar" || store === "sa_ar" || store === "ar"
                ? "rtl"
                : "ltr",
            textAlign:
              store === "ae_ar" || store === "sa_ar" || store === "ar"
                ? "right"
                : "left",
          }}
        >
          <Box sx={{ pt: 5 }}>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0 }}
              className="topsell-machine-grid"
            >
              <Box
                sx={{
                  minWidth: 120,
                  px: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "15px",
                  marginTop: "13px",
                }}
              >
                <Box
                  className="sortby-btn filter-box filter-input-box"
                  sx={{ width: { md: "297px", sm: "297px", xs: "297px" } }}
                >
                  <>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        bgcolor: "#fff",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                      className="filter-btn"
                      endIcon={
                        open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    >
                      <span
                        style={{
                          marginRight: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {filterIcon}
                      </span>
                      <span className="filter-text">
                        {t("inner_pages.domestic_machine.filter")}
                      </span>
                    </Button>
                    <Popover
                      className="filter-popver"
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} md={12}>
                              {filterAggregationProducts &&
                                filterAggregationProducts.map((data: any) => {
                                  return data?.attribute_code ===
                                    "manufacturer" ? (
                                    <Accordion
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",

                                        boxShadow: "none",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                          border: "1px solid #97a4a7",
                                          background: "#cad1d3",
                                        }}
                                      >
                                        <Typography>
                                          {t(
                                            "inner_pages.domestic_machine.menufacturer"
                                          )}{" "}
                                          <span
                                            className="menufacturer-brand title"
                                            style={{ marginLeft: "2px" }}
                                          >
                                            ({data?.options.length})
                                          </span>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          border: "1px solid #E5E3E3",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          sx={{
                                            flexWrap: "wrap",
                                            gap: "10px",
                                          }}
                                        >
                                          {data?.options.map((item: any) => {
                                            return (
                                              <Chip
                                                key={item?.value}
                                                onClick={() =>
                                                  handleChipClick(item)
                                                }
                                                label={`${item?.label} (${item?.count})`}
                                                className={`filter-chip ${
                                                  selectedMenufacturer.includes(
                                                    item?.value
                                                  )
                                                    ? "active"
                                                    : ""
                                                }`}
                                                component="a"
                                                href="#"
                                                clickable
                                                variant="outlined"
                                                sx={{
                                                  border: "1px solid #EFEFEF",
                                                  background: "#FAFAFA",
                                                  px: 4,
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            );
                                          })}
                                        </Stack>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  );
                                })}
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                              {filterAggregationProducts &&
                                filterAggregationProducts.map((data: any) => {
                                  return data?.attribute_code === "price" ? (
                                    <Accordion
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "20px",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                          border: "1px solid #97a4a7",
                                          background: "#cad1d3",
                                        }}
                                      >
                                        <Typography>
                                          {t(
                                            "inner_pages.domestic_machine.price"
                                          )}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          border: "1px solid #E5E3E3",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          sx={{ mb: 3 }}
                                        >
                                          <>
                                            <Chip
                                              sx={{
                                                border: "1px solid #EFEFEF",
                                                background: "#FAFAFA",
                                                px: 2,
                                                borderRadius: "5px",
                                              }}
                                              label={`${
                                                filterDataRes?.products
                                                  ?.aggregations &&
                                                filterDataRes?.products
                                                  ?.aggregations[0]?.currency
                                              } ${minFilterPrice}`}
                                              variant="outlined"
                                            />
                                            <Chip
                                              sx={{
                                                border: "1px solid #EFEFEF",
                                                background: "#FAFAFA",
                                                px: 2,
                                                borderRadius: "5px",
                                              }}
                                              label={`${
                                                filterDataRes?.products
                                                  ?.aggregations &&
                                                filterDataRes?.products
                                                  ?.aggregations[0]?.currency
                                              } ${maxFilterPrice}`}
                                              variant="outlined"
                                            />
                                          </>
                                          {/* ); */}
                                          {/* })} */}
                                        </Stack>
                                        <Box sx={{ width: 230 }}>
                                          <Slider
                                            getAriaLabel={() => "Price range"}
                                            value={priceValue}
                                            onChange={handleSliderChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={minFilterPrice}
                                            max={maxFilterPrice}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span className="directionrtl">
                                            {t(
                                              "inner_pages.domestic_machine.2365-products"
                                            )}
                                          </span>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  );
                                })}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </>
                </Box>
              </Box>
              <CardContent className="category_list">
                {(filteredPro === undefined || categoryProdLoading) ? (
                 <MainCardSkelton />
                ) : filteredPro?.length > 0 ? (
                  <GridUn
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2, md: 1.5 }}
                    columns={{ xs: 12 }}
                  >
                    {Array.from(Array(filteredPro?.length)).map((_, index) => (
                      <ProductMainCard filteredPro={filteredPro} setCartModalOpen={setCartModalOpen} setModalUrlKey={setModalUrlKey} index={index} setProductData={setProductData}/>
                    ))}
                  </GridUn>
                ) : (
                  <div>
                    {t("inner_pages.domestic_machine.no-product-found")}
                  </div>
                )}
              </CardContent>
            </Card>
          </Box>
          {totalPage && (
            <Box sx={{ pt: 4, pb: 2 }}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPage ? totalPage : 10}
                  shape="rounded"
                  onChange={handlePageChange}
                  defaultPage={selectedPage}
                />
              </Stack>
            </Box>
          )}

          {(htmlContentEnglish || htmlContentArabic) && (
            <Box className="coffee-grinders-container">
              <h2>{showLableForContainer}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: store.match("ar")
                    ? htmlContentArabic
                    : htmlContentEnglish,
                }}
              />
            </Box>
          )}
        </div>
        { isCartModalOpen &&  <CartModal
            open={isCartModalOpen}
            setCartModalOpen={setCartModalOpen}
            urlkey={modalUrlKey} 
            productdata={productData}
          />}
      </div>
    </>
  );
}

export default BrandPage;
