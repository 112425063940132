import React, { useContext, useEffect } from "react";
import { Grid, Typography, Card, CardContent, Box, Skeleton, useMediaQuery } from "@mui/material";
import GridUn from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CountryContext } from "../MainComponent";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
// import Loader from "../Layout/loader";
import theme from "../../../theme";

function BrandList() {
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const skeltonLength = isSmallScreen ? 40 : 60;
  // const initialColumnsToShow = 36;
  // const [showAllBrands] = useState(false);

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };

  const { data: brandRes, loading: brnadResLoading , refetch : brandListRefetch } = useQuery(
    queries.productQueries.GET_BRANDS_LIST,
    {
      context: {
        headers: customHeader,
      },
    }
  );
  const brandData =
    brandRes?.customAttributeMetadata?.items[0]?.attribute_options ?? [];

    useEffect(()=>{
      brandListRefetch();
    },[store ,brandListRefetch])

  return (
    <div>
      <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0 }}
              className="brand-grid"
            >
              <div className="section-title">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="span"
                  color="text.dark"
                >
                  {t("main.brand-title")}
                </Typography>
              </div>
              <CardContent sx={{ backgroundColor: "#fafafa" }}>
                <Box sx={{ flexGrow: 1, p: 2 }}>
                  <GridUn
                    container
                    spacing={2}
                    sx={{
                      "--Grid-borderWidth": "1px",
                      borderTop: "var(--Grid-borderWidth) solid",
                      borderLeft: "var(--Grid-borderWidth) solid",
                      borderColor: "divider",
                      "& > div": {
                        borderRight: "var(--Grid-borderWidth) solid",
                        borderBottom: "var(--Grid-borderWidth) solid",
                        borderColor: "divider",
                      },
                    }}
                  >
                    {brnadResLoading ? (
                      Array.from({ length: skeltonLength }).map((_, index) => (
                        <GridUn
                          key={index}
                          {...{ xs: 4, sm: 2, md: 1.5, lg: 1 }}
                          minHeight={110}
                        >
                          <div className="brand-img-box">
                              <Skeleton height={110} width={60}/>
                          </div>
                        </GridUn>
                      ))
                    ) : (
                      brandData.map(
                        (
                          brand: {
                            label: any;
                            link: any;
                            option_label_en: any;
                            swatch_image: string | undefined;
                            image_mobile: string | undefined;
                            image: string | undefined;
                            name: string | undefined;
                          },
                          index: React.Key | null | undefined
                        ) => (
                          <GridUn
                            key={index}
                            {...{ xs: 4, sm: 2, md: 1.5, lg: 1 }}
                            minHeight={110}
                          >
                            <div className="brand-img-box">
                              <Link
                                to={`/${store}/brand/${brand?.option_label_en
                                  .toLowerCase()
                                  .replaceAll(" ", "-")}.html`}
                              >
                                {brand?.swatch_image !== "" ? (
                                  <>
                                    <img
                                      src={brand?.swatch_image}
                                      alt={brand?.name}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </>
                                ) : (
                                  `${brand.label}`
                                )}
                              </Link>
                            </div>
                          </GridUn>
                        )
                      )
                    )}
                  </GridUn>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default BrandList;
