import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "./i18n";

const cache = new InMemoryCache({
  typePolicies: {
    SelectedConfigurableOption: {
      keyFields: ["id", "value_id"], 
    },
  },
});

const client = new ApolloClient({
  uri: "https://access.jebelz.com/graphql",
  // uri: "https://jebelz.com/graphql",
  // uri: "https://jebelzm2.ezmartech.com/graphql",
  // uri: "https://jebelzstg.ezmartech.com/graphql",
  cache,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

reportWebVitals();
